export const containerMaxW = 'xl:max-w-6xl xl:mx-auto';

export const appTitle = 'Subscriptions Dashboard';

export const getPageTitle = (currentPageTitle: string) =>
  `${currentPageTitle} — ${appTitle}`;

export const config = {
  openFdaApi: process.env.NEXT_PUBLIC_OPEN_FDA_API || '',
};
